export const PROJECT_CODE = 'bia-viet-wdbd-on-trade-activation-2024';

export const policy = `
<b>1.Tên chương trình khuyến mại: “TÍCH ĐIỂM ĐỔI QUÀ BIA VIỆT”</b>
<br /><br />
<b>2.Hàng hoá, dịch vụ khuyến mại:</b> Bia Việt (lon 330ml và chai 355ml).
<br /><br />
<b>3.Thời gian áp dụng:</b> từ 01/04/2024 đến 30/09/2024.
<br />
*Chương trình khuyến mại sẽ kết thúc trước thời hạn nếu đã hết quà tặng.
<br /><br />
<b>4.Địa bàn (phạm vi) khuyến mại:</b> Tây Ninh, Bình Dương, Bình Phước, Hồ Chí
Minh, Bà Rịa – Vũng Tàu, Đồng Nai, Bình Thuận, Hải Phòng.
<br /><br />
<b>5.Hình thức khuyến mại:</b> tích điểm đổi quà.
<br /><br />
<b>6.Khách hàng của chương trình tích điểm (đối tượng được hưởng khuyến
mại):</b> Người sinh sống tại lãnh thổ Việt Nam từ đủ 18 tuổi trở lên và có đủ năng
lực hành vi dân sự theo quy định của pháp luật Việt Nam; mua hàng hóa khuyến
mại trong thời gian và trong địa bàn khuyến mại theo nội dung chi tiết quy định tại
nội dung chương trình này.
<br /><br />
<b>7.Cơ cấu tích điểm, đổi điểm:</b>
<br />
<b>7.1 Cơ cấu tích điểm:</b> 01 lon Bia Việt 330ml hoặc 01 chai Bia Việt 355ml được quy
đổi tương ứng 01 điểm trên ứng dụng Zalo (thông qua trang <b>Zalo OA Bia Việt</b>).
<br />
*Trong lần đầu đăng ký, khách hàng được tặng 20 điểm ghi nhận vào thông tin
khách hàng.
<br /><br />
<b>7.2 Cơ cấu đổi điểm:</b>
<br />
- Tích đủ 40 điểm: đổi 01 Ly thủy tinh
<br />
- Tích đủ 60 điểm: đổi 01 Bình nước
<br />
- Tích đủ 160 điểm: đổi 01 Túi du lịch
<br />
*Mỗi khách hàng có thể tích điểm để đổi quà nhiều lần, không giới hạn số lần đổi
quà của khách hàng.
<br />
*Vào tháng sinh nhật của khách hàng (trong thời gian áp dụng ở Mục 3), khách
hàng đã bấm <b>Quan tâm</b> trang <b>Zalo OA Bia Việt</b> sẽ nhận được voucher miễn phí
04 chai/ lon Bia Việt.
<br /><br />
<b>8.Nội dung chi tiết thể lệ chương trình đổi điểm:</b>
<br />
<b>8.1 Điều kiện, cách thức, thủ tục cụ thể khách hàng phải thực hiện để được tham
gia chương trình đổi điểm:</b>
<br />
- Trong thời gian khuyến mại khách hàng từ đủ 18 tuổi trở lên trên địa bàn thành
phố tại Mục 4 đến địa điểm có tổ chức chương trình khuyến mãi. Khách hàng sẽ
tích được 01 điểm khi 01 lon Bia Việt 330ml hoặc 01 chai Bia Việt 355ml. Khách
hàng tích đủ điểm có thể đổi được quà tương ứng (cụ thể mục 7.2)
<br />
- Không giới hạn số lượng quà tặng cho mỗi khách hàng.
<br />
- Chương trình sẽ kết thúc trước thời hạn nếu đã tặng hết giải thưởng.
<br />
- Quà tặng không quy đổi thành tiền mặt.
<br />
<b>8.2 Thời gian, cách thức phát hành bằng chứng xác định tích điểm:</b>
<br />
- Trong thời gian diễn ra chương trình khuyến mại, khách hàng sử dụng sản phẩm
Bia Việt và được nhân viên tiếp thị Bia Việt quy đổi điểm số tương đương với số
lượng lon/ chai Bia Việt khách hàng sử dụng.
<br /><br />
<b>8.3 Quy định về bằng chứng xác thực đổi điểm:</b>
<br />
- Nhân viên tiếp thị quét mã QR của khách hàng để đối chiếu thông tin trên giao
diện của khách hàng và trên app hệ thống của nhân viên. Hệ thống sẽ thông báo và
cộng số điểm tương ứng vào tài khoản khách hàng.
<br /><br />
<b>8.4 Thông báo trúng thưởng và cách thức xác định trúng thưởng:</b>
<br />
- Khách hàng thao tác bấm đổi điểm trên <b>Zalo OA Bia Việt</b> sau đó mang thông tin
đã đổi điểm đến cửa hàng trong thời gian có nhân viên tiếp thị của Bia Việt để
nhân viên hỗ trợ đổi quà.
<br /><br />
<b>9.Đầu mối giải đáp thắc mắc cho khách hàng về các vấn đề liên quan đến
chương trình khuyến mại:</b>
<br />
- Các vấn đề liên quan đến chương trình khuyến mại khách hàng liên hệ hotline
<a href="tel:1900 1845">1900 1845</a> hoặc <b>Zalo OA Bia Việt</b> để được giải đáp.
<br /><br />
<b>10. Trách nhiệm công bố thông tin:</b>
<br />
- Công ty TNHH nhà máy bia Heineken Việt Nam sẽ thông báo công khai chi tiết
nội dung của thể lệ chương trình khuyến mại trên trang:
<a href="https://www.facebook.com/BiaVietOfficial">www.facebook.com/BiaVietOfficial</a> và tại địa điểm bán sản phẩm khuyến mại sau
khi có văn bản xác nhận đăng ký chương trình khuyến mại của cơ quan quản lý
nhà nước về thương mại.
<br />
- Công ty TNHH nhà máy bia Heineken Việt Nam có trách nhiệm công bố danh
sách khách hàng đã đổi quà trên trang <a href="https://www.facebook.com/BiaVietOfficial">www.facebook.com/BiaVietOfficial</a>.
<br /><br />
<b>11. Các quy định khác:</b>
<br />
- Khách hàng tham gia chương trình khuyến mại này vẫn được tham gia các chương
trình khuyến mại khác do Công ty TNHH Nhà Máy Bia Heineken Việt Nam đang
đồng thời triển khai thực hiện.
<br />
- Công ty TNHH Nhà Máy Bia Heineken Việt Nam được sử dụng tên, hình ảnh của
người tiêu dùng trúng thưởng vào mục đích quảng cáo thương mại.
<br />
- Khi phát sinh tranh chấp, khiếu nại liên quan đến Chương trình, Công ty TNHH
Nhà Máy Bia Heineken Việt sẽ đứng ra trực tiếp giải quyết theo quy định của
pháp luật hiện hành. Việc thực hiện chương trình khuyến mại phải tuân thủ theo
quy định của pháp luật về hoạt động xúc tiến thương mại về các quy định khác của
pháp luật có liên quan.
`;

export const getProjectCode = () => PROJECT_CODE;
